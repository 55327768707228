<template>
  <ContentBlock>
    <div class="top" style="padding: 12px 12px 0 20px;" v-if="isShowHead">
      <b>服务能力证明材料</b>
    </div>
    <div class="materialContent">
      <div class="left">
        <ul class="navContainer">
          <li v-for="material in MaterialLists" :key="material.label" :class="{'active': activeName === material.value, 'required': material.required}" @click="handleClick(material.value, activeName)">
            <span>{{ material.label }}</span> <i class="el-icon-info" v-if="material.showError"  style="color:red;font-size: 14px;margin-top:2px;"></i>
          </li>
        </ul>
      </div>
      <div class="right">
        <EngineerInformation
          v-show="activeName === 'EngineerInformation'"
          ref="JZEngineerInformation"
          :isDisabled="isDisabled"
          :serviceInfo="serviceInfo"
          :isShowHead="isShowHead"
          @updateService="updateService"
        >
        </EngineerInformation>
        <PerformanceInformation
          v-show="activeName === 'PerformanceInformation'"
          ref="JZPerformanceInformation"
          :isDisabled="isDisabled"
          :serviceInfo="serviceInfo"
          :isShowHead="isShowHead"
          @updateService="updateService"
        >
        </PerformanceInformation>
        <ServiceRecords
          v-show="activeName === 'ServiceRecords'"
          ref="JZServiceRecords"
          :isDisabled="isDisabled"
          :serviceLists="serviceLists"
          :isShowHead="isShowHead"
          @updateRecordLists="updateRecordLists"
        >
        </ServiceRecords>
      </div>
    </div>
  </ContentBlock>
</template>

<script>
import {updateTenantInformation} from '@/api/systemSetting';
export default {
  name: '',
  props: {
    isShowHead: {
      type: Boolean,
      default () {
        return false;
      },
    },
    currentInfo: {
      type: Object,
      default () {
        return {};
      },
    },
    isDisabled: {
      type: Boolean,
      default () {
        return false;
      },
    },
  },
  watch: {
    currentInfo: {
      handler (val) {
        if (val) {
          this.serviceInfo = val.tenantServiceCapacityRespDTO || {};
          this.serviceLists = val.tenantServiceCapacityRecordRespDTOs || [];
        }
      },
      immediate: true,
      deep: true,
    },
  },
  components: {
    EngineerInformation: ()=>import('./component/EngineerInformation.vue'),
    PerformanceInformation: ()=>import('./component/PerformanceInformation.vue'),
    ServiceRecords: ()=>import('./component/ServiceRecords.vue'),
  },
  methods: {
    handleClick (value, preActiveName) {
      if (value !== preActiveName) {
        if (this.$route.name === 'CorporateCertification') {
          // this.handleUpdateService(preActiveName);
        }
        this.activeName = value;
      }
    },
    handleUpdateService (preActiveName) {
      if (preActiveName === 'EngineerInformation') {
        this.$refs.JZEngineerInformation.handleUpdate();
      } else if (preActiveName === 'PerformanceInformation') {
        this.$refs.JZPerformanceInformation.handleUpdate();
      } else if (preActiveName === 'ServiceRecords') {
        this.$refs.JZServiceRecords.handleUpdate();
      }
    },
    updateService (info) {
      if (this.$route.name === 'CorporateCertification') {
        let serviceInfo = this.serviceInfo || {
          engineerNumber: '',
          salesOfThreeYears: '',
          tenantFileReqDTOs: [],
        };
        serviceInfo = {
          ...serviceInfo,
          ...info,
        };
        this.$emit('updateData', serviceInfo, 'tenantServiceCapacityRespDTO');
      } else {
        let serviceInfo = this.serviceInfo || {
          engineerNumber: '',
          salesOfThreeYears: '',
          tenantFileReqDTOs: [],
        };
        serviceInfo = {
          ...serviceInfo,
          ...info,
        };
        let currentParams = {
          dataType: '1',
          tenantServiceCapacityReqDTO: serviceInfo,
        };
        updateTenantInformation(currentParams).then(() => {
          this.$emit('updatePage');
        });
      }
    },
    updateRecordLists (list) {
      if (this.$route.name === 'CorporateCertification') {
        this.$emit('updateData', list, 'tenantServiceCapacityRecordRespDTOs');
      } else {
        let tempLists = list.map(i => ({
          ...i,
          tenantServiceCapacityRecordDetailRespDTOs: i.tenantServiceCapacityRecordDetailRespDTOs.map(j => ({
            ...j,
            hospitalId: i.hospitalId,
            hospitalName: i.hospitalName,
            operateType: i.operateType,
          })),
        })).map(i => i.tenantServiceCapacityRecordDetailRespDTOs).flat();
        let currentParams = {
          dataType: '1',
          tenantServiceCapacityRecordReqDTOs: tempLists,
        };
        updateTenantInformation(currentParams).then(() => {
          this.$emit('updatePage');
        });
      }
    },
    checkForm (list) {
      let resultLists = [];
      let results = list.map(name => {
        return new Promise((resolve, reject) => {
          let formObj = null;
          formObj = this.$refs[name].$refs.JZForm;
          if (name === 'JZServiceRecords') {
            // 这里需要验证表格
            let result = this.handleValidateServiceRecords();
            if (result) {
              resolve();
            } else {
              resultLists.push({
                name,
              });
              reject(new Error('验证失败'));
            }
          } else {
            formObj.validate((valid, object) => {
              if (valid) {
                resolve();
              } else {
                resultLists.push({
                  ...object,
                  name,
                });
                reject(new Error('验证失败'));
                this.$nextTick(() => {
                  var isError = document.querySelectorAll('.is-error');
                  isError[0].querySelector('input').focus();
                });
              }
            });
          }
        });
      });
      return Promise.all(results).catch(() => {
        return Promise.reject(resultLists);
      });
    },
    async handleValidateForm () {
      this.errorFormLists = [];
      return await this.checkForm(this.formArr).then((valid) => {
        if (valid) {
          return true;
        }
      }).catch((err)=>{
        console.log(err);
        this.errorFormLists = (err || []).map(i => i.name);
        if (this.errorFormLists && this.errorFormLists.length) {
          this.$message.warning('请补充完成必填信息');
          return false;
        }
        return false;
      });
    },
    handleValidateServiceRecords () {
      let serviceLists = this.serviceLists || [];
      if (!(serviceLists && serviceLists.length)) {
        return false;
      }
      let isError = serviceLists.every(i => i.hospitalName);
      if (!isError) {
        return isError;
      }
      let currentLists = serviceLists.map(i=>i.tenantServiceCapacityRecordDetailRespDTOs).flat();
      isError = currentLists.every(i => i.deviceName);
      if (!isError) {
        return isError;
      }
      isError = currentLists.every(i => i.deviceModel);
      if (!isError) {
        return isError;
      }
      isError = currentLists.every(i => i.deviceId || (!i.deviceId && i.deviceManufacturer));
      return isError;
    },
  },
  computed: {
    formArr () {
      return ['JZEngineerInformation', 'JZPerformanceInformation', 'JZServiceRecords'];
    },
    MaterialLists () {
      let errorFormLists = this.errorFormLists;
      return [
        {label: '工程师信息', value: 'EngineerInformation', type: '1', required: this.isShowHead, showError: errorFormLists.includes('JZEngineerInformation')},
        {label: '近三年业绩信息', value: 'PerformanceInformation', required: this.isShowHead, showError: errorFormLists.includes('JZPerformanceInformation')},
        {label: '客户服务记录', value: 'ServiceRecords', type: '3', required: this.isShowHead, showError: errorFormLists.includes('JZServiceRecords')},
      ];
    },
  },
  data () {
    return {
      activeName: 'EngineerInformation',
      serviceInfo: {},
      serviceLists: [],
      errorFormLists: [],
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
.content_block{
  padding: 0 20px 20px 0;
  min-height: calc(100vh - 420px);
}
.navContainer{
  list-style: none;
  width: 180px;
  margin-bottom: 0;

  margin-right: 10px;
  li{
    height:40px;
    line-height: 40px;
    margin-bottom: 0;
    padding-left: 20px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #5F6A7A;
    letter-spacing: 0;
    text-align: left;
    line-height: 20px;
    padding: 10px 20px 10px 20px;
    cursor: pointer;
    &.active{
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #237FFA;
      letter-spacing: 0;
      text-align: left;
      line-height: 20px;
      background: #E2F3FE;
      position: relative;
      &::after{
        content: '';
        width:2px;
        height:20px;
        position: absolute;
        left: 0;
        top: 10px;
        background: #237FFA;
      }
    }
    &.required{
      position: relative;
      display: flex;
      span{
        flex: 1;
      }
      &::before{
        content: '*';
        width:8px;
        height:8px;
        position: absolute;
        left: 10px;
        top: 12px;
        color: #FF5959;
      }
    }
  }
}
.materialContent{
  display: flex;
  padding-top: 8px;
  padding-right: 10px;
  .right{
    flex: 1;
    background: #FFFFFF;
    border: 1px solid #DFE2E8;
    border-radius: 4px;
  }
}
</style>
